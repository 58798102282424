<template>
  <q-page-container class="bg-white">

    <q-page class="flex flex-center justify-center">

      <div class="col col-lg-8 col-sm-9 col-xs-10 q-pa-md" style="min-width: 370px">
        <h4 class="text-dark-gray text-weight-medium">{{ $t('auth.registration_email') }}</h4>
        <q-separator class=""  />
        <q-form
          @submit="submit"
          class="q-gutter-md"
        >
          <q-input
            color="light-gray"
            label-color="light-gray"
            square
            outlined
            type="email"
            v-model="email"
            label="Email"
            :hint="$t('auth.registration_email_hint')"
            lazy-rules
            :rules="[val => !!val || $t('validation.required', { field: $t('generals.email')}), val => isValidEmail(val)]"
          />
          <div class="text-center q-mt-lg">
            <q-btn no-caps label="Invia email di ripristino" type="submit" color="primary" class="q-px-lg q-py-xs"/>
            <div class="q-mt-sm">
              <q-btn no-caps :label="$t('auth.back_to_login')" type="reset" size="sm" flat to="/auth/login"/>
            </div>
          </div>
        </q-form>
      </div>
    </q-page>
  </q-page-container>
</template>

<style>
</style>

<script>
import User from 'src/store/models/User'
export default {
  data () {
    return {
      email: null
    }
  },
  methods: {
    isValidEmail (email) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(email) || this.$t('validation.regex', { field: this.$t('generals.email') })
    },
    submit () {
      this.$q.loading.show()
      User.$resetPasswordRequest(this.email)
        .then((response) => {
          this.$q.loading.hide()
          this.$q.notify({
            color: 'positive',
            textColor: 'white',
            icon: 'check',
            message: 'Email inviata, controlla la tua casella di posta',
            position: 'top'
          })

          this.$router.push({ path: '/auth/password-reset-requested' })
        }).catch((e) => {
          console.log(e)
          this.$q.loading.hide()
          this.$q.notify({
            color: 'negative',
            textColor: 'white',
            icon: 'times',
            message: 'Errore',
            position: 'top'
          })
        })
    }
  }
}
</script>
